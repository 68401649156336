import { useState, useEffect } from 'react';
import useMedia from 'hooks/useMedia';

const useDarkMode = () => {
  const [theme, setTheme] = useState('light');
  const prefersDarkMode = useMedia(['(prefers-color-scheme: dark)'], [true], false);

  const toggleTheme = () => {
    setTheme(prevTheme => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light';
      window.localStorage.setItem('theme', newTheme);
      return newTheme;
    });
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    if (localTheme) {
      setTheme(localTheme);
    } else if (prefersDarkMode) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }, [prefersDarkMode]);

  return [theme, toggleTheme];
};

export default useDarkMode;