exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-ourdream-js": () => import("./../../../src/pages/articles/ourdream.js" /* webpackChunkName: "component---src-pages-articles-ourdream-js" */),
  "component---src-pages-articles-systemstheory-js": () => import("./../../../src/pages/articles/systemstheory.js" /* webpackChunkName: "component---src-pages-articles-systemstheory-js" */),
  "component---src-pages-articles-wisps-js": () => import("./../../../src/pages/articles/wisps.js" /* webpackChunkName: "component---src-pages-articles-wisps-js" */),
  "component---src-pages-hcol-post-js": () => import("./../../../src/pages/hcol_post.js" /* webpackChunkName: "component---src-pages-hcol-post-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mylife-js": () => import("./../../../src/pages/mylife.js" /* webpackChunkName: "component---src-pages-mylife-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-project-immortify-js": () => import("./../../../src/pages/project_immortify.js" /* webpackChunkName: "component---src-pages-project-immortify-js" */)
}

